import { useUserContext } from "../context/UserContext";
import { signInWithGoogle } from "../utils/SignInUtils";
import { getUserData, saveUserData } from "../utils/UserUtils";
import "./MapEditor.css";

// ログイン画面コンポーネント
function SignIn() {
  const { user, setUser } = useUserContext();

  function doSignIn() {
    signInWithGoogle()
      .then((result) => {
        // ユーザー情報の保存(Providorによって提供される)
        setUser(result.user);
        console.log("認証成功:", result.user);
        // ユーザーデータの保存
        const userData = { name: result.user.displayName, email: result.user.email };
        saveUserData(user, userData).then(() => {
          console.log("test ::ユーザーデータを保存しました");
          // ユーザーデータの取得
          getUserData(user).then((data) => {
            console.log("test ::取得したユーザーデータ:", data);
          });
        });

      })
      .catch((error) => {
        // 認証失敗
        console.error("認証エラー:", error);
      });
  }

  return <button onClick={doSignIn}>Sign in with Google</button>;
}

export default SignIn;
