import { getAuth } from "firebase/auth";
import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import "./App.css";
import { UserProvider } from "./context/UserContext";
import config from "./firebaseConfig";
import MapEditor from "./pages/MapEditor";
import SignIn from "./pages/SignIn";

const auth = getAuth(config);

// アプリケーションのメインコンポーネント
function App() {
  const [authuser] = useAuthState(auth);
  return (
    <UserProvider>
      <Router>
        <Routes>
          <Route 
            path="/" 
            element={authuser ? <Navigate to="/edit" /> : <SignIn />} />
          <Route
            path="/edit"
            element={authuser ? <MapEditor /> : <Navigate to="/" />}
          />
        </Routes>
      </Router>
    </UserProvider>
  );
}

export default App;
