import { Box } from '@mui/material';
import { getAuth, signOut } from 'firebase/auth';
import React, { useEffect } from "react";
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import { Map } from '../components/Map';
import './MapEditor.css';

function MapEditor() {
  const navigate = useNavigate(); // ナビゲーションフックを使用
  const auth = getAuth();
  console.log("auth:", auth);
  const [user, loding, error] = useAuthState(auth);
  console.log("user:", user);

  useEffect(() => {
    if (loding) {
      // ローディング中の処理
    } else if (error) {
      // エラー時の処理
      console.error("Auth Error:", error);
    } else if (user) {
      // ログイン済みの処理
    } else {
      // 未ログインの処理
      navigate("/");
    }
  // }, [user, loding, error, navigate]);
  }, []);

  // ログアウト処理
  const handleLogout = () => {
    signOut(auth).then(() => {
      // ログアウト成功時にルートページにリダイレクト
      navigate("/");
    }).catch((error) => {
      // エラー処理
      console.error("Logout Error:", error);
    });
  };

  return (
    <>
      <Box
        sx={{
          display: { xs: "none", md: "box" },
        }}
      >
        <button onClick={handleLogout}>Logout</button>
      </Box>
      <Map mapapikey="AIzaSyCteBUIZCl5LMrvKO0BPQ2SslaEeepGvmo" /> 
    </>
  );
}

export default MapEditor;