import { doc, getDoc, getFirestore, setDoc } from "firebase/firestore";
import config from '../firebaseConfig';

// Firebaseサービスの取得
const db = getFirestore(config);

// Firestoreにユーザーデータを保存する関数
export function saveUserData(user, data) {
  const userDoc = doc(db, "users", user.uid);
  return setDoc(userDoc, data);
}

// Firestoreからユーザーデータを取得する関数
export function getUserData(user) {
  const userDoc = doc(db, "users", user.uid);
  return getDoc(userDoc).then((docSnap) => {
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      throw new Error("No such document!");
    }
  });
}

// Firestoreに現在選択されているルートIDを保存する関数
export function saveSelectedRouteId(userId, routeId) {
  const userDocRef = doc(db, "users", userId);
  // "selectedRouteId"フィールドにrouteIdを保存
  return setDoc(userDocRef, { selectedRouteId: routeId }, { merge: true });
}

// Firestoreから現在選択されているルートIDを取得する関数
export function getSelectedRouteId(userId) {
  console.log("test :: getSelectedRouteId",userId);
  // userIdが空の場合はエラーを返す
  if (!userId) {
    const errorMessage = "Error: userId is required but was not provided.";
    console.error(errorMessage);
    // Promise.rejectを使用して、呼び出し元にエラーを伝播させる
    return Promise.reject(new Error(errorMessage));
  }
  const userDocRef = doc(db, "users", userId);
  console.log("test :: getSelectedRouteId -> userDocRef", userDocRef);
  return getDoc(userDocRef).then((docSnap) => {
    console.log("test :: getSelectedRouteId -> getDoc");
    if (docSnap.exists() && docSnap.data().selectedRouteId) {
      return docSnap.data().selectedRouteId;
    } else {
      return "";
    }
  }).catch((error) => {
    console.error("Error getting selected route ID:", error);
    return "";
  });
}