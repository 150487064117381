import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { deleteRouteData, getAllRoutesData } from '../../utils/RouteUtils'; // Firestoreから経路データを取得する関数
import { saveSelectedRouteId } from '../../utils/UserUtils'; // Firestoreに選択された経路IDを保存する関数
import styles from "./RouteList.module.css";

export const RouteList = forwardRef(({ userId, onRouteSelected, onRoutePublish },ref) => {
  const [routes, setRoutes] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchRoutes = async () => {
    setLoading(true);
    try {
      const data = await getAllRoutesData(userId);
      setRoutes(data);
    } catch (error) {
      console.error("Error fetching routes:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRoutes();
  }, [userId]);
  
  // 親コンポーネントからのリロード呼び出しを可能にする
  useImperativeHandle(ref, () => ({
    reloadRoutes: () => {
      fetchRoutes();
    }
  }));

  const handlePublish = async (route) => {
    // Firestoreに選択された経路IDを保存
    await saveSelectedRouteId(userId, route.id);
    onRoutePublish(route);
  };

  // ルートの削除処理
  const handleDelete = async (route) => {
    const isConfirmed = window.confirm("この経路を削除しますか？");
    if (isConfirmed) {
      await deleteRouteData(userId, route.id);
      // 削除後のルート一覧を再取得または更新するロジックをここに追加
      fetchRoutes();
      onRouteSelected(null);
    }
  };

  if (loading) return <div>Loading routes...</div>;
  if (!routes.length) return <div>No routes available.</div>;

  return (
    <ul className={styles.routelist}>
      {routes.map((route) => (
        <li key={route.id} className={styles.listRow} onClick={() => onRouteSelected(route)}>
          <div className={styles.routeId}>{route.id}</div>
          <div className={styles.buttonGroup}>
            <button
              className={styles.publishButton}
              onClick={(e) => {
                e.stopPropagation(); // ルート選択のイベント伝播を停止
                handlePublish(route);
              }}
            >
              配信
            </button>
            <button
              className={styles.deleteButton}
              onClick={(e) => {
                e.stopPropagation(); // ルート選択のイベント伝播を停止
                handleDelete(route);
              }}
            >
              削除
            </button>
          </div>
        </li>
      ))}
    </ul>
  );
});

