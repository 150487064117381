/* eslint-disable no-undef */
import {
  faBars,
  faMapMarkerAlt,
  faMotorcycle,
  faSave,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import { useUserId } from "../../hooks/useUserId";
import { getRouteData, saveRouteData } from "../../utils/RouteUtils";
import { getSelectedRouteId, saveSelectedRouteId } from "../../utils/UserUtils";
import styles from "./Roadmap.module.css";
import { RouteList } from "./RouteList";
import { WaypointList } from "./WaypointList";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import FlagIcon from "@mui/icons-material/Flag";
import MapIcon from "@mui/icons-material/Map";
import MenuIcon from "@mui/icons-material/Menu";
import NearMeIcon from "@mui/icons-material/NearMe";
import PublishIcon from "@mui/icons-material/Publish";
import RemoveRoadIcon from "@mui/icons-material/RemoveRoad";
import StartIcon from "@mui/icons-material/Start";
import SwapVertIcon from "@mui/icons-material/SwapVert";

import { DialogTitle, IconButton } from "@mui/material";
import { createTheme } from "@mui/material/styles";

import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 768,
      lg: 1025,
      xl: 1536,
    },
  },
});

const socket = io();

export const Roadmap = () => {
  const GOOGLE_MAPS_API_MAX_WAYPOINTS = 8;
  const MODE_VIEW = "閲覧モード";
  const MODE_ORIGIN = "出発地入力モード";
  const MODE_DESTINATION = "目的地入力モード";
  const MODE_WAYPOINT = "経由地入力モード";
  const MODE_M_DELETE = "モバイル版削除";
  const MAP_LONGPRESS_THRESHHOLD_MS = 500;
  // 地図基本情報
  const ref = useRef(null);
  const mapRef = useRef(null);

  function mapInstance() {
    return mapRef.current;
  }
  // モード
  const [mode, setMode] = useState(MODE_VIEW);
  const modeRef = useRef(mode);

  // 現在地情報
  const [currentLocation, setCurrentLocation] = useState(null);
  const currentLocationRef = useRef(null);

  // 出発地情報
  const [origin, setOrigin] = useState(null);
  const originMarkerRef = useRef(null);

  // 目的地情報
  const [destination, setDestination] = useState(null);
  const destinationMarkerRef = useRef(null);

  // 経由地情報
  const [waypoints, setWaypoints] = useState([]);
  const waypointsRef = useRef(waypoints);
  const waypointsMarkersRef = useRef([]);
  const [inputRouteId, setInputRouteId] = useState("");

  // 初期化完了フラグ
  const [isInitCompleted, setIsInitCompleted] = useState(false);

  // ルート描画用のサービスとレンダラ
  const directionsServiceRef = useRef(null);
  const directionsRendererRef = useRef(null);

  const routeListRef = useRef(null);

  // 現在のユーザID取得
  const userId = useUserId();
  console.log("userId", userId);
  // menuVisible状態の定義（初期値はfalse）
  const [menuVisible, setMenuVisible] = useState(false);

  // メニュー表示の切り替えを行う関数
  const toggleMenu = () => {
    setMenuVisible((prevVisible) => !prevVisible);
  };

  // 選択されているルートID取得
  const [selectedRouteId, setSelectedRouteId] = useState("");

  // getSelectedRouteId(userId).then((data) => {
  // })
  // .catch((error) => {
  //   console.error("Error getSelectedRouteId:", error);
  // });

  //-------------------------------------
  // mobileSwapRouteダイアログ
  //-------------------------------------
  const [swapDialogOpen, setSwapDialogOpen] = useState(false);
  const handleSwapDialogOpen = () => {
    setSwapDialogOpen(true);
  };
  const handleSwapDialogClose = () => {
    setSwapDialogOpen(false);
  };
  //-------------------------------------
  // MUI
  //-------------------------------------
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  // const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsMenuOpen(open);
  };

  // スマホ用メニューアイテム
  let menuItems = [
    { icon: <AccountCircleIcon />, text: `ユーザID: ${userId}` },
    { icon: <MapIcon />, text: `配信済ルートID: ${selectedRouteId}` },
  ];

  function dump(...args) {
    console.log(...args);
  }
  // =====================
  // mapの初期設定
  // =====================

  // 現在地を取得する関数
  const getCurrentLocation = () => {
    const isDemo = false;
    if (isDemo) {
      return new Promise((resolve, reject) => {
        resolve({ coords: { latitude: 35.777295, longitude: 138.996131667 } });
      });
    }
    return new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        reject(new Error("Geolocation is not supported by your browser."));
      } else {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      }
    });
  };

  useEffect(() => {
    // alert("useEffect");
    if (ref.current && !window.google) {
      console.log("Google Maps API is not loaded");
      return;
    }
    socket.on("routeNotification", (msg) => {
      console.log("Received message: ", msg);
      // API呼び出しやその他のアクション
    });

    (async (userId) => {
      let selectedId = await getSelectedRouteId(userId);
      console.log("getSelectedRouteId", selectedId);
      setSelectedRouteId(selectedId);

      // 現在地時の取得
      console.log(`userId: ${userId}, selectedRouteId: ${selectedId}`);
      let position = await getCurrentLocation();
      const { latitude, longitude } = position.coords;
      currentLocationRef.current = {
        latitude: latitude,
        longitude: longitude,
      };
      const initMap = new window.google.maps.Map(ref.current, {
        center: { lat: latitude, lng: longitude },
        zoom: 11,
        gestureHandling: "greedy",
        fullscreenControl: false,
      });

      if (initMap) {
        console.log("Map is initialized at current location");
        mapRef.current = initMap;
        setIsInitCompleted(true);
        onMapLoad();
        directionsServiceRef.current = new google.maps.DirectionsService();
        directionsRendererRef.current = new google.maps.DirectionsRenderer();
        directionsRendererRef.current.setMap(initMap);

        // 現在のルート情報取得
        let route = await getRouteData(userId, selectedId);
        onRouteSelected(route);
      } else {
        console.log("Failed to create map");
      }
    })(userId);

    // getCurrentLocation()
    //   .then((position) => {
    //   })
    //   .catch((error) => {
    //     console.error("Error InitProcess:", error);
    //     // ここでデフォルトの位置を設定するか、エラーメッセージを表示することもできます。
    //   });

    return () => {
      socket.off("routeNotification");
      if (mapInstance()) {
        window.google.maps.event.clearListeners(mapInstance(), "mousedown");
        window.google.maps.event.clearListeners(mapInstance(), "mouseup");
      }
    };
  }, []);

  useEffect(() => {
    if (!isInitCompleted) {
      console.log("not completed");
      return;
    }
    dump("completed");
    setIsInitCompleted(true);
  }, [isInitCompleted]);

  useEffect(() => {
    modeRef.current = mode; // コンポーネントがレンダリングされるたびに、最新のモードをrefに格納
  }, [mode]); // 依存配列にmodeを指定して、modeが変更されるたびに実行

  useEffect(() => {
    console.log("currentLocation changed");
    currentLocationRef.current = currentLocation; // コンポーネントがレンダリングされるたびに、最新の現在地をrefに格納
    // マップの中央値を現在地に設定
    if (mapInstance() && currentLocation) {
      mapInstance().setCenter({
        lat: currentLocation.latitude,
        lng: currentLocation.longitude,
      });
    }
  }, [currentLocation]); // 依存配列にcurrentLocationを指定して、currentLocationが変更されるたびに実行

  useEffect(() => {
    console.log("point changed");
    waypointsRef.current = waypoints; // コンポーネントがレンダリングされるたびに、最新の経由地をrefに格納
    renderRoute();
  }, [origin, destination, waypoints]); // 依存配列にdestinationを指定して、destinationが変更されるたびに実行

  const clearWaypoints = () => {
    setWaypoints([]);
  };
  // =====================
  // mapInstance 依存 ハンドラ
  // =====================
  /**
   * マップが読み込まれたら実行される
   */
  const onMapLoad = () => {
    // マップが読み込まれたら実行される
    setEventHandlers();
    // addCustomAreasLeftTop();
  };

  const handleInputClear = () => {
    setInputRouteId("");
    clearMap();
  };

  const handleDispcenter = () => {
    getCurrentLocation()
      .then((position) => {
        const { latitude, longitude } = position.coords;
        setCurrentLocation({ latitude, longitude });
      })
      .catch((error) => {
        console.error("Error InitProcess:", error);
        // ここでデフォルトの位置を設定するか、エラーメッセージを表示することもできます。
      });
  };

  // モード切替機能
  const handleModeChange = (newMode) => setMode(newMode);
  // イベントハンドラ切り替え
  const setEventHandlers = () => {
    // タイマーIDを保持する変数
    let pressTimer;
    // 長押しを検出する関数
    const handleLongPress = (event) => {
      // 長押しによる処理をここに記述
      const latitude = event.latLng.lat();
      const longitude = event.latLng.lng();
      dump("long press", { latitude, longitude });
      dump("mode", modeRef.current);
      const mode = modeRef.current;
      switch (mode) {
        case MODE_ORIGIN:
          dump("origin update", { latitude, longitude });
          setOrigin({ latitude, longitude });
          break;
        case MODE_DESTINATION:
          dump("destination update", { latitude, longitude });
          setDestination({ latitude, longitude });
          break;
        case MODE_WAYPOINT:
          dump("waypoint add", { latitude, longitude });
          if (waypointsRef.current.length < GOOGLE_MAPS_API_MAX_WAYPOINTS) {
            let newWaypoints = [
              ...waypointsRef.current,
              { latitude, longitude },
            ];
            dump("waypoints", newWaypoints);
            setWaypoints(newWaypoints);
          } else {
            alert("経由地の最大数に達しました。");
          }
          break;
        // 他のモードに応じた処理を追加
        default:
          break;
      }
    };
    // mousedown イベントリスナー
    const onMouseDown = (event) => {
      if (event?.touches?.length === 2) {
        // 2本の指でタッチされた
        // not handle
        return;
      }
      console.log("touchstart");
      pressTimer = setTimeout(() => handleLongPress(event), MAP_LONGPRESS_THRESHHOLD_MS); // 500ms で長押しと判定
    };
    // mouseup イベントリスナー
    const onMouseUp = () => {
      console.log("touchend");
      clearTimeout(pressTimer); // 長押しタイマーをクリア
    };
    const onDrag = () => {
      console.log("touchmove");
      clearTimeout(pressTimer); // 長押しタイマーをクリア
    };
    // イベントリスナーを設定
    mapInstance().addListener("touchstart", onMouseDown);
    mapInstance().addListener("touchend", onMouseUp);
    mapInstance().addListener("touchmove", onDrag);
    mapInstance().addListener("mousedown", onMouseDown);
    mapInstance().addListener("mouseup", onMouseUp);
    mapInstance().addListener("mousemove", onDrag);
    mapInstance().addListener("drag", onDrag);
  };

  function getRoute() {
    return {
      origin: origin,
      destination: destination,
      wayPoints: waypoints,
    };
  }
  function isRenderRoute(route) {
    return route && route.origin && route.destination;
  }
  function convertPositionToGmapLatLng(position) {
    if (!position) {
      return null;
    }
    console.log(
      `convertPositionToGmapLatLng ${position.latitude}, ${position.longitude}`
    );
    return new window.google.maps.LatLng(position.latitude, position.longitude);
  }
  function convertPositionToGmapWaypoints(position) {
    if (!position) {
      return [];
    }
    let result = null;
    if (Array.isArray(position)) {
      result = position.map((pos) => {
        return {
          location: new window.google.maps.LatLng(pos.latitude, pos.longitude),
        };
      });
      console.log(`convertPositionToGmapWaypoints result ${result}`);
      return result;
    } else {
      return [];
    }
  }
  function renderRoute() {
    // ルート表示
    let route = getRoute();
    if (route.origin) {
      // ルートの開始ピンの描画
      renderOriginPin(route.origin);
    }
    if (isRenderRoute(route)) {
      console.log("★★renderRoute");
      // ルートの描画
      renderPolyline(route);
      // ルートの終点にピンを立てる
      renderDestinationPin(route.destination);
      // ルートの経由地にピンを立てる
      renderWaypointPins(route.wayPoints);
    } else {
      console.log("no route to render");
    }
  }
  function renderPolyline(route) {
    if (isRenderRoute(route)) {
      // ルートの描画
      dump("renderPolyline", route);
      directionsServiceRef.current.route(
        {
          origin: convertPositionToGmapLatLng(route.origin),
          destination: convertPositionToGmapLatLng(route.destination),
          waypoints: convertPositionToGmapWaypoints(route.wayPoints),
          optimizeWaypoints: false,
          travelMode: "DRIVING",
          // travelMode: "TWO_WHEELER",
          avoidFerries: true,
          avoidHighways: true,
          avoidTolls: true,
        },
        (result, status) => {
          if (status === "OK") {
            console.log("directionsService OK");
            directionsRendererRef.current.setOptions({
              hideRouteList: true,
              suppressMarkers: true,
              suppressPolylines: false,
              suppressInfoWindows: false,
              draggable: false,
              preserveViewport: true,
              polylineOptions: {
                strokeColor: "#FF2281",
                strokeOpacity: 0.6,
                strokeWeight: 15,
              },
            });
            console.log("directionsRendererRef", directionsRendererRef.current);
            directionsRendererRef.current.setDirections(result);
          } else {
            console.error(`error fetching directions ${result}`);
          }
        }
      );
    } else {
      console.log("no route to render");
    }
  }
  function renderOriginPin(origin) {
    // 既存のマーカーがあれば削除
    if (originMarkerRef.current) {
      originMarkerRef.current.setMap(null);
    }
    // 新しい出発地が設定されていれば、新しいマーカーを作成
    if (origin) {
      const newPos = convertPositionToGmapLatLng(origin);
      const marker = new window.google.maps.Marker({
        position: newPos,
        map: mapInstance(),
        title: "出発地",
      });
      // 新しいマーカーの参照を保存
      originMarkerRef.current = marker;
    }
  }

  function renderDestinationPin(destination) {
    // 既存のマーカーがあれば削除
    if (destinationMarkerRef.current) {
      destinationMarkerRef.current.setMap(null);
    }
    // 新しい目的地が設定されていれば、新しいマーカーを作成
    if (destination) {
      const newPos = convertPositionToGmapLatLng(destination);
      const marker = new window.google.maps.Marker({
        position: newPos,
        map: mapInstance(),
        title: "目的地",
      });
      // 新しいマーカーの参照を保存
      destinationMarkerRef.current = marker;
    }
  }
  function renderWaypointPins(waypoints) {
    // 既存のマーカーをすべて削除
    waypointsMarkersRef.current.forEach((marker) => marker.setMap(null));
    waypointsMarkersRef.current = [];

    // 新しい経由地に基づいてマーカーを作成
    waypoints.forEach((waypoint, index) => {
      const newPos = convertPositionToGmapLatLng(waypoint);
      const marker = new window.google.maps.Marker({
        position: newPos,
        map: mapInstance(),
        title: `経由地${index + 1}`,
      });
      // マーカーのリストに新しいマーカーを追加
      waypointsMarkersRef.current.push(marker);
    });
  }

  function saveToFirestore(forceRouteId = null) {
    if (typeof forceRouteId !== "string") {
      forceRouteId = null;
    }

    if (!userId) {
      //サインインさせる
      alert("ログインしてください");
      return;
    }
    const route = getRoute();
    console.log("forceRouteId", forceRouteId);
    console.log("saveToFirestore", route);
    console.log("inputRouteId", inputRouteId);
    const routeId = forceRouteId ? forceRouteId : inputRouteId;
    console.log("routeId", routeId);
    if (!routeId) {
      alert("ルートIDを入力してください");
      return;
    }
    // routeIdのバリデーション（半角英数字,-,_のみ）
    if (!routeId.match(/^[0-9a-zA-Z-_]+$/)) {
      alert("ルートIDは半角英数字,-,_のみです");
      return;
    }
    try {
      saveRouteData(userId, routeId, {
        origin: route.origin,
        destination: route.destination,
        waypoints: route.wayPoints,
      });
      routeListRef.current.reloadRoutes();
      alert("ルートを保存しました");
    } catch (error) {
      // 何らかのエラーが発生した場合
      console.error("Error saving route data:", error);
      alert("保存に失敗しました。詳細: " + error.message);
    }
  }

  function mobilePublish() {
    //　保存と配信を行うことを確認する
    if (!window.confirm("ルートを保存して配信しますか？")) {
      return;
    }
    // ルートIDを今日の日付と時刻から生成
    const routeId = new Date().toISOString().replace(/\D/g, "");
    setInputRouteId(routeId);
    saveToFirestore(routeId);
    onRoutePublish({ id: routeId });
  }

  function SwapDialog({ open, onClose }) {
    return (
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="simple-dialog-title"
      >
        <DialogActions>
          <DialogTitle id="simple-dialog-title">ルート入替</DialogTitle>
          <IconButton onClick={onClose} color="inherit">
            <CloseIcon />
          </IconButton>
        </DialogActions>
        <DialogContent>
          {/* WaypointList コンポーネントをここに配置 */}
          <WaypointList waypoints={waypoints} setWaypoints={setWaypoints} />
        </DialogContent>
      </Dialog>
    );
  }

  function onRouteSelected(route) {
    if (!route) {
      setInputRouteId("");
      clearMap();
      return;
    }
    console.log("onRouteSelected", route);
    setInputRouteId(route.id);
    setOrigin(route.origin);
    setDestination(route.destination);
    setWaypoints(route.waypoints);
    setCurrentLocation(route.origin);
  }
  function onRoutePublish(route) {
    console.log("onRoutePublish", route);

    const publish = async (route) => {
      // Firestoreに選択された経路IDを保存
      await saveSelectedRouteId(userId, route.id);
      setSelectedRouteId(route.id);
      console.log("ws publish", route);
      socket.emit("notifyRoute", route.id);
    };
    publish(route);
  }
  // const fetchRoutes = async () => {
  //   alert("fetchRoutes");
  //   try {
  //     const data = await getAllRoutesData(userId);
  //     data.forEach((route) => {
  //       console.log("route", route);
  //       menuItems.push({ icon: <MapIcon />, text: `ルートID: ${route.id}` });
  //     });
  //   } catch (error) {
  //     console.error("Error fetching routes:", error);
  //   }
  // };

  function clearMap() {
    //設定されている出発地を削除
    setOrigin(null);
    //設定去れている目的地を削除
    setDestination(null);
    //設定されている経由地を削除
    setWaypoints([]);
    //出発地のマーカーを削除
    if (originMarkerRef.current) {
      originMarkerRef.current.setMap(null);
    }
    //目的地のマーカーを削除
    if (destinationMarkerRef.current) {
      destinationMarkerRef.current.setMap(null);
    }
    //経由地のマーカーを削除
    waypointsMarkersRef.current.forEach((marker) => marker.setMap(null));
    waypointsMarkersRef.current = [];
    directionsRendererRef.current.setMap(null);
    //描画されているルートを削除
    directionsRendererRef.current.setDirections({ routes: [] });
    directionsRendererRef.current.setMap(mapInstance());
  }
  function clearInput() {
    setInputRouteId("");
  }

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
        <div ref={ref} id="map" className={styles.roadmap} />

        {/* モバイル版 ここから */}
        <Box
          sx={{
            display: { xs: "block", md: "none" },
          }}
        >
          <IconButton
            sx={{
              mr: 2,
              position: "absolute",
              top: "100px",
              left: "30px",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              border: "none",
              paddingLeft: "10px",
              paddingRight: "10px",
              marginTop: "10px", // ボタン間のマージン
              height: "40px",
              cursor: "pointer",
            }}
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <IconButton
            sx={{
              mr: 2,
              position: "absolute",
              top: "150px",
              left: "30px",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              border: "none",
              paddingLeft: "10px",
              paddingRight: "10px",
              marginTop: "10px", // ボタン間のマージン
              height: "40px",
            }}
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={(e) => {
              handleDispcenter();
            }}
          >
            <NearMeIcon />
          </IconButton>
          <IconButton
            sx={{
              mr: 2,
              position: "absolute",
              top: "200px",
              left: "30px",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              border: "none",
              paddingLeft: "10px",
              paddingRight: "10px",
              marginTop: "10px", // ボタン間のマージン
              height: "40px",
            }}
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={(e) => {
              mobilePublish();
            }}
          >
            <PublishIcon />
          </IconButton>
          <IconButton
            sx={{
              mr: 2,
              position: "absolute",
              top: "250px",
              left: "30px",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              border: "none",
              paddingLeft: "10px",
              paddingRight: "10px",
              marginTop: "10px", // ボタン間のマージン
              height: "40px",
            }}
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={(e) => {
              handleSwapDialogOpen();
            }}
          >
            <SwapVertIcon />
          </IconButton>
          <SwapDialog open={swapDialogOpen} onClose={handleSwapDialogClose} />
          <Drawer anchor="left" open={isMenuOpen} onClose={toggleDrawer(false)}>
            <Box
              sx={{ width: 250 }}
              role="presentation"
              onClick={toggleDrawer(false)}
              onKeyDown={toggleDrawer(false)}
            >
              <List>
                {menuItems.map((item, index) => (
                  <ListItem button key={index}>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.text} />
                  </ListItem>
                ))}
              </List>
            </Box>
          </Drawer>

          <BottomNavigation
            showLabels
            value={mode}
            onChange={(event, newValue) => {
              if (newValue === mode) {
                return;
              }
              if (newValue === MODE_M_DELETE) {
                // モバイル版のダイアログで削除確認を行う
                if (window.confirm("ルートを削除しますか？")) {
                  clearWaypoints();
                }
                return;
              }
              console.log("newValue", newValue);
              // setValue(newValue);
              handleModeChange(newValue);
            }}
          >
            <BottomNavigationAction icon={<StartIcon />} value={MODE_ORIGIN} />
            <BottomNavigationAction
              icon={<FlagIcon />}
              value={MODE_DESTINATION}
            />
            <BottomNavigationAction
              icon={<AddLocationIcon />}
              value={MODE_WAYPOINT}
            />
            <BottomNavigationAction
              icon={<RemoveRoadIcon />}
              value={MODE_M_DELETE}
            />
          </BottomNavigation>
        </Box>
        {/* PC版 ここから */}
        <Box
          sx={{
            display: { xs: "none", md: "block" },
          }}
        >
          <button
            className={`${styles.overlayButton} ${styles.buttonMenu}`}
            onClick={() => toggleMenu()}
          >
            <FontAwesomeIcon icon={faBars} /> メニュー
          </button>
          <button
            className={`${styles.overlayButton} ${styles.buttonCurrentLocation}`}
            onClick={() => handleDispcenter()}
          >
            <FontAwesomeIcon icon={faMapMarkerAlt} /> 現在地
          </button>
          <button
            className={`${styles.overlayButton} ${styles.buttonOriginInput} ${
              mode === MODE_ORIGIN ? styles.buttonActive : ""
            }`}
            onClick={() => handleModeChange(MODE_ORIGIN)}
          >
            <FontAwesomeIcon icon={faMotorcycle} /> 出発地入力
          </button>
          <button
            className={`${styles.overlayButton} ${
              styles.buttonDestinationInput
            } ${mode === MODE_DESTINATION ? styles.buttonActive : ""}`}
            onClick={() => handleModeChange(MODE_DESTINATION)}
            disabled={!origin}
          >
            <FontAwesomeIcon icon={faMotorcycle} /> 目的地入力
          </button>
          <button
            className={`${styles.overlayButton} ${styles.buttonWaypointInput} ${
              mode === MODE_WAYPOINT ? styles.buttonActive : ""
            }`}
            onClick={() => handleModeChange(MODE_WAYPOINT)}
            disabled={!destination}
          >
            <FontAwesomeIcon icon={faMotorcycle} /> 経由地入力
          </button>
          <button
            className={`${styles.overlayButton} ${styles.buttonClearWaypoints}`}
            onClick={() => clearWaypoints()}
            disabled={!waypoints.length}
          >
            <FontAwesomeIcon icon={faTrash} /> 経路全削除
          </button>
          <div className={`${styles.overlayContainer}`}>
            <input
              className={`${styles.overlayInput} ${styles.inputRouteId}`}
              pattern="^[a-zA-Z0-9]*$"
              type="text"
              value={inputRouteId}
              onChange={(e) => setInputRouteId(e.target.value)}
              disabled={!destination}
              placeholder="ルートIDを入力"
              id="inputRouteId"
            />
            <span
              className={`${styles.overlayLabel} ${styles.clearIcon}`}
              onClick={() => {
                clearInput();
              }}
              disabled={!destination}
            >
              ×
            </span>
          </div>
          <button
            className={`${styles.overlayButton} ${styles.buttonSaveSettings}`}
            disabled={!destination}
            onClick={saveToFirestore}
          >
            <FontAwesomeIcon icon={faSave} /> 設定保存
          </button>
        </Box>

        <div
          className={styles.menuArea}
          style={{ display: menuVisible ? "block" : "none" }}
        >
          <div>ユーザID: {userId}</div>
          <div>配信済ルートID: {selectedRouteId}</div>
          {/* <div>mode: {mode}</div> */}
          <div>
            出発地:{" "}
            {origin ? `${origin.latitude}, ${origin.longitude}` : "未設定"}
          </div>
          <div>
            目的地:{" "}
            {destination
              ? `${destination.latitude}, ${destination.longitude}`
              : "未設定"}
          </div>
          <div>ルート一覧: </div>
          <RouteList
            ref={routeListRef}
            userId={userId}
            onRouteSelected={onRouteSelected}
            onRoutePublish={onRoutePublish}
          />
          <div>経路情報: </div>
          <WaypointList waypoints={waypoints} setWaypoints={setWaypoints} />
        </div>
      </Box>
    </>
  );
};
