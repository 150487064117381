// firebaseConfig.js
import { initializeApp } from 'firebase/app';
// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAetSqQsyyf1xWwXaN23fPEk6sGHQ6qOTw",
  authDomain: "caramel-graph-300514.firebaseapp.com",
  projectId: "caramel-graph-300514",
  storageBucket: "caramel-graph-300514.appspot.com",
  messagingSenderId: "919416809762",
  appId: "1:919416809762:web:708f8cba88e1684805dd7b"
};

// Initialize Firebase
const config = initializeApp(firebaseConfig);
export default config;