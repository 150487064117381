import { Box } from '@mui/material';
import React from 'react';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { StrictModeDroppable } from './StrictModeDroppable';
import styles from './WaypointList.module.css';

const reorder = (list, startIndex, endIndex) => {
  console.log("reorder",list,startIndex,endIndex);
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  console.log("reorder",result);
  return result;
};

export const WaypointList = ({ waypoints, setWaypoints }) => {
  const onDragEnd = (result) => {
    console.log("dragend",result);
    if (!result.destination) {
      return;
    }

    const items = reorder(
      waypoints,
      result.source.index,
      result.destination.index
    );

    setWaypoints(items);
  };

  const removeItem = (index) => {
    const newWaypoints = Array.from(waypoints);
    console.log("removeItem",newWaypoints,index);
    newWaypoints.splice(index, 1);
    setWaypoints(newWaypoints);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <StrictModeDroppable droppableId="droppable">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {waypoints.map((waypoint, index) => (
              <Draggable key={index} draggableId={`item-${index}`} index={index}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{ marginBottom: '8px', ...provided.draggableProps.style }}
                  >
                    <Box
                      sx={{
                        display: { xs: "block", md: "none" },
                      }}
                    >
                      <div className={styles.listRow} style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ flexGrow: 1 }}>{`経由地 ${index + 1}`}</span>
                        <button
                          className={styles.deleteButton}
                          onClick={() => removeItem(index)}>削除</button>
                      </div>
                    </Box>
                    <Box
                      sx={{
                        display: { xs: "none", md: "block" },
                      }}
                    >
                        <div className={styles.listRow} style={{ display: 'flex', alignItems: 'center' }}>
                          <span style={{ flexGrow: 1 }}>{`経由地 ${index + 1}: ${waypoint.latitude}, ${waypoint.longitude}`}</span>
                          <button 
                            className={styles.deleteButton}
                            onClick={() => removeItem(index)}>削除</button>
                        </div>
                    </Box>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </StrictModeDroppable>
    </DragDropContext>
  );
};
