import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import config from '../firebaseConfig';

// Firebaseサービスの取得
const auth = getAuth(config);

// Google認証でユーザーをサインインする関数
export function signInWithGoogle() {
  const provider = new GoogleAuthProvider();
  return signInWithPopup(auth, provider);
  // return signInWithRedirect(auth, provider);
}
