import { collection, deleteDoc, doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import { db } from './FirebaseUtil';

// 経路情報をFirestoreに保存する関数
export function saveRouteData(userId, routeId, routeData) {
  if (!userId) {
    throw new Error("User ID is not defined!");
  }
  if (!routeId) {
    throw new Error("Route ID is not defined!");
  }
  if (!routeData) {
    throw new Error("Route data is not defined!");
  }
  const routeDoc = doc(db, "users", userId, "routes", routeId);
  return setDoc(routeDoc, routeData);
}

// 特定のユーザーの経路情報を取得する関数
export function getRouteData(userId, routeId) {
  if (!userId) {
    throw new Error("User ID is not defined!");
  }
  if (!routeId) {
    throw new Error("Route ID is not defined!");
  }
  const routeDoc = doc(db, "users", userId, "routes", routeId);
  return getDoc(routeDoc).then((docSnap) => {
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      throw new Error("No such route!");
    }
  });
}

// ユーザーの全経路情報を取得する関数
export function getAllRoutesData(userId) {
  if (!userId) {
    throw new Error("User ID is not defined!");
  }
  const routesCollection = collection(db, "users", userId, "routes");
  console.log(routesCollection);
  return getDocs(routesCollection).then((querySnapshot) => {
    let routes = [];
    querySnapshot.forEach((doc) => {
      routes.push({ id: doc.id, ...doc.data() });
    });
    return routes;
  });
}

// 経路情報を削除する関数
export const deleteRouteData = async (userId, routeId) => {
  const routeDocRef = doc(db, "users", userId, "routes", routeId);
  await deleteDoc(routeDocRef);
};