import { Status, Wrapper } from '@googlemaps/react-wrapper';
import React from 'react';
import { Roadmap } from './parts/Roadmap';

/**
 * マップ表示
 * 目的地の地図と、天気の地図の表示切り替えを行います
 *
 */
const render = (status) => {
  if (status === Status.LOADING) return <h1>Loading...</h1>;
  if (status === Status.FAILURE) return <h1>Error loading maps</h1>;
  return null;
};
export const Map = ({mapapikey, mapMode, historyPoints, distPoint, wayPoints, mapZoom}) => {

  // const [mapApiKey, setMapApiKey] = useState(mapapikey);

  // useEffect(() => {
  //   setMapMode(mode);
  // } , [mode]);

  // =====================
  return (
    // <div className={styles.map}>
    <div>
      <Wrapper apiKey={mapapikey} render={render} libraries={["places"]}>
        <Roadmap />
      </Wrapper>
    </div>
  );
}